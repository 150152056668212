<!-- ======= Contact Section ======= -->
<section  class="contact" id="Contact">
  <div class="container" data-aos="fade-up" >

    <h2 class="main-heading-all text-center">Contact Us</h2>
    <div class="row mt-1 d-flex" data-aos="fade-right" data-aos-delay="100">

      <div class="col-lg-6 " >
        <div class="contact__info">
          <h2 class="contact__info__title">Get In Touch</h2>
          <p class="contact__info__desc">Kesari MICE can provide professional support/service for your organization’s
            MICE activities.</p>
          <div class="info">
            <div class="address">
              <i class="bi bi-geo-alt"></i>
              <h4>Head Office Address</h4>
              <p class="mb-30">
                Land Square Shopping Complex, 2nd Floor, Fergusson College Rd, Next to Hotel Rupali, Ganeshwadi, Shivajinagar, Pune, Maharashtra 411004

              </p>
            </div>
            <div class="phone">
              <i class="bi bi-briefcase"></i><h4>Branch Contact</h4>
             
              <div class="ml-15">
                 <h4><span><i class="bi bi-telephone"></i> Head Office</span>  <a href="tel:020 6646 5555">020 6646 5555 / </a> <a href="tel:+91 90289 77878">+91 90289 77878</a></h4>
              <h4><span> <i class="bi bi-phone"></i> Delhi</span> <a href="tel:+91 98716 27062">+91 98716 27062</a></h4>
              <h4><span> <i class="bi bi-phone"></i> Bangalore</span>  <a href="tel:+91 98865 33657">+91 98865 33657</a></h4>
              <h4><span> <i class="bi bi-phone"></i> Mumbai</span> <a href="tel:+91 91455 61122">+91 91455 61122</a></h4>
              <h4><span> <i class="bi bi-envelope"></i> Email us at</span> <a href="mailto:sales@kesarimice.in">sales@kesarimice.in</a></h4>
             
              </div>
              <!-- <p>020 66485000</p> -->
            </div>
            <div class="address">
              <i class="bi bi-geo-alt"></i>
              <h4>Location</h4> 


                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3783.2117724776986!2d73.83877437411378!3d18.519329832573955!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2bf878f4ef659%3A0xde1fcd293e81324a!2sKesari%20Tours%20-%20Pune%20FC%20Road!5e0!3m2!1sen!2sin!4v1689931599441!5m2!1sen!2sin"   style="border:0; width:100%;" allowfullscreen=""  loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
              <!-- <p>
              S.No.26/1/1/2K/7, <br>
              Vishal Nagar, 
              Aundh Hinjewadi Rd, <br>
              Jagtap Dairy, 
              Wakad, Pune, 
              Maharashtra 411027

              41/13,Karve Road,<br />
              Opp HP Petrol Pump,<br />
              Erandwane, PUNE 411 004, INDIA
            </p> -->
            </div>

            <!-- <div class="email">
              <i class="bi bi-envelope"></i>
              <h4>Email:</h4>

            </div> -->

         
          </div>
        </div>
      </div>

      <div class="col-lg-6 mt-5 mt-lg-0" data-aos="fade-left" data-aos-delay="100">

        <div class="contact__form">
          <h4 style="font-size: 20px;font-weight: bold;margin-bottom: 5px;">Corporate Travel Booking/Enquiry Form:</h4>
          <p style="font-size: 16px;color: #666;margin-bottom: 20px;">Kindly spare a few moments to fill the below form
            and enable Team KesariMICE to understand your travel requirements and take care of your MICE requirements in
            a flawless and professional manner. </p>


          <!-- Show success message -->
          <!-- <div *ngIf="showSuccessMessage">
            <div class="alert alert-success">
              <strong>Success:</strong> Message sent successfully!
            </div>
          </div> -->

          <!-- Show warning message -->
          <div *ngIf="showWarningMessage">
            <div class="alert alert-warning">
              <strong>Warning:</strong> There was a problem sending the message.
            </div>
          </div>

          <form [formGroup]="contactForm" (ngSubmit)="onSubmit()" class="php-email-form needs-validation">
            <div class="row">
              <div class="col-md-6 form-group">
                <input type="text" name="Name" class="form-control" formControlName="Name" placeholder="Your Name" />
                <div *ngIf="contactForm.controls['Name'].invalid && contactForm.controls['Name'].touched">
                  <span *ngIf="checkError('Name', 'required')">*Please enter your name.</span>
                </div>
              </div>
              <div class="col-md-6 form-group">
                <input type="text" name="companyname" class="form-control" formControlName="companyname"
                  placeholder="Company Name" />
                <div *ngIf="contactForm.controls['companyname'].invalid && contactForm.controls['companyname'].touched">
                  <span *ngIf="checkError('companyname', 'required')">*Please enter company name.</span>
                </div>
              </div>
              <div class="col-md-6 form-group">
                <input type="email" class="form-control" name="email" formControlName="email" placeholder="Your Email"
                  required />
                <div *ngIf="contactForm.controls['email'].invalid && contactForm.controls['email'].touched">
                  <span *ngIf="checkError('email', 'required')">*Please enter a valid email address.</span>
                  <span *ngIf="checkError('email', 'email')"></span>
                  <span *ngIf="checkError('email', 'pattern')">*Please enter a valid email address.</span>
                </div>
              </div>
              <div class="col-md-6 form-group">
                <input type="text" class="form-control" name="contact" maxlength="10" formControlName="contact"
                  placeholder="Enter your Mobile Number" required />
                <div *ngIf="contactForm.controls['contact'].invalid && contactForm.controls['contact'].touched">
                  <span *ngIf="checkError('contact', 'required')">*Please enter a Phone Number.</span>
                  <span *ngIf="checkError('contact', 'maxlength')"></span>
                  <span *ngIf="checkError('contact', 'pattern')">*Please enter a valid mobile number</span>
                </div>
              </div>

              <div class="col-md-6 form-group">
                <select name="grptype" class="form-control select" formControlName="grptype"
                  placeholder="What are you looking at">
                  <option value="" selected disabled hidden>What are you looking at</option>
                  <option>Meetings</option>
                  <option>Incentive tours</option>
                  <option>Conference</option>
                  <option>Exhibition</option>
                  <option>Educational tours</option>
                </select>
                <!-- <div *ngIf="contactForm.controls['grptype'].invalid && contactForm.controls['grptype'].touched">
                <span *ngIf="checkError('grptype', 'required')">Please select option</span>
              </div> -->
              </div>

              <div class="col-md-6 form-group">
                <input type="text" class="form-control" name="destination" formControlName="destination"
                  placeholder=" Specify the destination" />
                <!-- <div *ngIf="contactForm.controls['destination'].invalid && contactForm.controls['destination'].touched">
                <span *ngIf="checkError('destination', 'required')">Please include your Destination Name in the required
                  field.</span>
              </div> -->
              </div>

              <div class="col-md-6 form-group">
                <input type="date" class="form-control" name="dateOfTravel" formControlName="dateOfTravel"
                  placeholder="Month of Travel" />
                <div
                  *ngIf="contactForm.controls['dateOfTravel'].invalid && contactForm.controls['dateOfTravel'].touched">

                  <!-- <span *ngIf="checkError('dateOfTravel','required')">Please include the date of travel in the required field.</span> -->
                  <span *ngIf="checkError('dateOfTravel','invalidDate')">Ensure that the date of travel field is filled
                    with the appropriate date.</span>
                </div>
              </div>
              <div class="col-md-6 form-group">
                <input type="text" class="form-control" name="NoOfDays" formControlName="NoOfDays" maxlength="2"
                  placeholder="Enter number of days" />
                <div *ngIf="contactForm.controls['NoOfDays'].invalid && contactForm.controls['NoOfDays'].touched">
                  <!-- <span *ngIf="checkError('NoOfDays', 'required')">Please include No. of Days in the required
                  field.</span> -->
                  <span *ngIf="checkError('NoOfDays', 'pattern')">Please include No. of Days Alphabets are not valid!
                  </span>

                </div>
              </div>


              <div class="form-group ">
                <textarea class="form-control" name="description" formControlName="description" rows="5"
                  placeholder="Message"></textarea>
                <!-- <div *ngIf="contactForm.controls['description'].invalid && contactForm.controls['description'].touched">
                  <span *ngIf="checkError('description', 'required')">Please include your Description in the required field.</span>
                </div> -->
              </div>

            </div>



            <div class="text-center">
              <button type="submit" class="btn btn-primary fw-bold read-morebtn" [disabled]="!contactForm.valid || disableSubmitbtn" >Send Message</button>
            </div>
            
          </form>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- End Contact Section -->