<!-- ======= About Us Section ======= -->
<section id="About-Us" class="about section-pdng">
  <div class="container" data-aos="fade-up" style="z-index: 1; position: relative">


    <div class="row content">
      <div class="col-lg-7">
        <h2 class="main-heading-all">We Make Your Travel <br>More Enjoyable</h2>
        <p class="about__desc"> Kesari MICE is a division of Kesari Tours Pvt. Ltd. which established its unique
          identity in the Indian
          tourism industry through innovative ideas and excellent services. Established in 1984 with a pledge ‘When you
          make a promise to your Guests, honour it’, Kesari today has a reputation for dedicated service, honest and
          transparent deals, no hidden costs, great value holidays and 100% Guest satisfaction. </p>

        <div class="row clearfix  about__feature">
          <div class="col-lg-4 col-md-4 col-sm-4 col-4 d-flex">
            <div class="about__feature__title">
              40+
            </div>
            <div class="about__feature__desc">
              Year's of<br>Experience
            </div>
          </div>

          <div class="col-lg-4 col-md-4 col-sm-4 col-4 d-flex">
            <div class="about__feature__title">
              10K
            </div>
            <div class="about__feature__desc">
              Tours<br>Activity
            </div>
          </div>

          <div class="col-lg-4 col-md-4 col-sm-4 col-4 d-flex">
            <div class="about__feature__title">
              50K
            </div>
            <div class="about__feature__desc">
              Happy<br>Customer
            </div>
          </div>


        </div>
        <ul class="about__list">
          <li>
            <i class="bi-check2-all icon-clr"></i>Trusted Travel Partner Worldwide
          </li>
          <li>
            <i class="bi-check2-all icon-clr"></i>Customized and Corporate Travel
          </li>
          <li>
            <i class="bi-check2-all icon-clr"></i>Excellence Service
          </li>
          <li>
            <i class="bi-check2-all icon-clr"></i>Expert Tour Managers
          </li>
        </ul>

        <p class="about__desc"> Corporate travel management in India has become a niche market in the travel
          industry. Being one of the major corporate travel consultants in the travel industry.         
        </p>

      </div>
      <div class="col-lg-5 col-xs-12 pt-4 pt-lg-0 btns-learn-more">
        <img src="../../assets/img/about.jpg" width="509" height="407" loading="lazy" alt="Kesari MICE - India’s most preferred outdoor official corporate tours packages organizers in Mumbai expertise as corporate tours and travel planners.">
      </div>
    </div>
  </div>
</section>
<!-- End About Us Section -->