<!-- ======= Top Bar ======= -->
<!-- <div class="topnav custom-topnav hidden-xs hidden-sm"> -->
<div class="main-section">
  <!-- <div id="topbar" class="d-flex align-items-center">
      <div
        class="container d-flex align-items-center justify-content-center justify-content-md-between"
      >
        <div class="contact-info d-flex align-items-center">
          <a href="mailto:contact@example.com">
            <i class="bi bi-envelope-fill"></i> 
            info@example.com
          </a>
          <a href="tel:1800 233 2333">
            <i class="bi bi-phone-fill phone-icon"></i>
            1800 233 2333
          </a>
        </div>
    
        <div class="cta d-none d-md-block">
          <a href="#contact" class="scrollto">Get Started</a>
        </div>
      </div>
    </div> -->



  <div class="top-hed">

    <div class="container top-hed__wrapper ds-none">

      <div class="top-hed__right">
        <ul class="top-hed__ul">
          <li class="">
            <span class="">
            </span>
            <!-- <span>kesari@support.com</span> -->
          </li>
          <li>
            <a href="tel: 18002661100">
              <span>
                <i aria-hidden="true" class="fas fa-phone-alt elementor-phone"></i>
              </span>
              <span class="elementor-icon-list-text">18002661100</span>
            </a> &nbsp;
            (Toll-free)
          </li>

        </ul>
        <ul class="social-links list-unstyled mb-0 d-flex justify-content-start">

          <!-- <li><a target="_blank" href="https://www.facebook.com/KesariTours" class="facebook"><i class="fa-brands fa-facebook-f"></i></a></li>
            <li><a target="_blank" href="https://in.linkedin.com/company/kesari-tours" class="instagram"><i class="fa-brands fa-instagram"></i></a></li> -->
          <li><a target="_blank" href="https://twitter.com/KesariMICE" class="twitter"><i
                class="fa-brands fa-twitter"></i></a></li>
          <!-- <li><a target="_blank" href="#" class="youtube"><i class="fa-brands fa-youtube"></i></a></li> -->
          <li><a target="_blank" href="https://kesarimice.blogspot.in/" class="linkdin"><i
                class="fa-solid fa-blog"></i></a></li>
          <li><a target="_blank" href="https://in.linkedin.com/in/kesari-mice-4241712a" class="linkdin"><i
                class="fa-brands fa-linkedin-in"></i></a></li>

        </ul>
        <!-- <span class="top-hed__offer">
          We Provide The &nbsp;<b>Best Service &amp; Discounts</b>&nbsp; For You
        </span> -->
      </div>
    </div>

  </div>

  <!-- ======= Header ======= -->
  <header id="header" class="main-navigation d-flex align-items-center"
    [ngClass]="{ 'mat-elevation-z5': true, sticky: isSticky }">
    <div class="container">
      <div class=" d-flex hed-wrapper">
        <a routerLink="" class="logo">
          <img src="../../../assets/img/logo.png" width="125" height="50" alt="logo" loading="eager" /><br />
          <!-- <a routerLink="" class="sub-logo">
            <img src="../../../assets/img/logo-texts.png" />
          </a> -->
        </a>
        <button class="mobile-nav-toggle" type="button" data-toggle="collapse" data-target="#navbarColor03"
          aria-controls="navbarColor03" aria-expanded="false" aria-label="Toggle navigation"
          (click)="isCollapsed = !isCollapsed">
          <i class="bi bi-list"></i>
        </button>
        <!-- Uncomment below if you prefer to use an image logo -->
        <!-- <a href=index.html" class="logo"><img src="assets/img/logo.png" alt="" class="img-fluid"></a>-->

        <nav id="navbar" class="navbar ms-auto dk-view">
          <ul>
            <li><a class="nav-link scrollto" href="#Home">Home</a></li>
            <li><a class="nav-link scrollto" href="#About-Us">About Us</a></li>
            <li>
              <a class="nav-link scrollto" href="#Corporate-Travel-Services">Corporate Travel Services </a>
            </li>

            <li>
              <a class="nav-link scrollto" href="#Mice-Tourism">MICE Tourism </a>
            </li>
            <li>
              <a class="nav-link scrollto" href="#Itineraries">Itineraries</a>
            </li>
            <li>
              <a class="nav-link scrollto" href="#Happy-Corporate">Happy Corporates</a>
            </li>
            <li><a class="nav-link scrollto" href="#Careers">Careers</a></li>
            <li><a class="nav-link scrollto btn-active contact" href="#Contact">Contact</a></li>
          </ul>
        </nav>

        <div class="collapse navbar-collapse toggle-button" id="menu-toggle" id="navbarColor03"
          [ngbCollapse]="isCollapsed">


          <button aria-label="Close" type="button" mat-dialog-close class="btn btn-light map-close btn-close"
            (click)="close()"></button>

          <ul for="menu-toggle" class="nav navbar-nav ms-auto mb-view">
            <!-- <button
              class="mobile-nav-toggle"
              type="button"
              data-toggle="collapse"
              data-target="#navbarColor03"
              aria-controls="navbarColor03"
              aria-expanded="false"
              aria-label="Toggle navigation"
              (click)="isCollapsed = !isCollapsed"
            >
              <i class="bi mobile-nav-toggle bi-x"></i>
            </button> -->

            <li class="nav-item active">
              <a class="nav-link scrollto active" href="#Home" (click)="isCollapsed = !isCollapsed">Home</a>
            </li>
            <li>
              <a class="nav-link scrollto" href="#About-Us" (click)="isCollapsed = !isCollapsed">About Us</a>
            </li>
            <li class="nav-item">
              <a class="nav-link scrollto" href="#Corporate-Travel-Services" (click)="isCollapsed = !isCollapsed">
                Corporate Travel Services
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link scrollto" href="#Mice-Tourism" (click)="isCollapsed = !isCollapsed">
                MICE Tourism
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link scrollto" href="#Itineraries" (click)="isCollapsed = !isCollapsed">
                Itineraries</a>
            </li>
            <li class="nav-item">
              <a class="nav-link scrollto" href="#Happy-Corporate" (click)="isCollapsed = !isCollapsed">Happy
                Corporates</a>
            </li>
            <li class="nav-item">
              <a class="nav-link scrollto" href="#Careers" (click)="isCollapsed = !isCollapsed">Careers</a>
            </li>

            <li class="nav-item">
              <a class="nav-link scrollto" href="#Contact" (click)="isCollapsed = !isCollapsed">Contact Us</a>
            </li>

            <li class="nav-item icon-txt">
              <a href="tel: 18002661100">
                <span>
                  <i aria-hidden="true" class="fas fa-phone-alt elementor-phone"></i>
                </span>
                <span class="elementor-icon-list-text">18002661100</span>
              </a>
            </li>

            <ul class="social-links icon-list clearfix list-unstyled">

              <li><a target="_blank" href="https://twitter.com/KesariMICE" class="twitter"><i
                    class="fa-brands fa-twitter"></i></a></li>
              <!-- <li><a target="_blank" href="#" class="youtube"><i class="fa-brands fa-youtube"></i></a></li> -->
              <li><a target="_blank" href="https://kesarimice.blogspot.in/" class="linkdin"><i
                    class="fa-solid fa-blog"></i></a></li>
              <li><a target="_blank" href="https://in.linkedin.com/in/kesari-mice-4241712a" class="linkdin"><i
                    class="fa-brands fa-linkedin-in"></i></a></li>
            </ul>
          </ul>
        </div>
        <!-- .navbar -->
      </div>
    </div>
  </header>
</div>