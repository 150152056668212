<section id="Home" class="pt-0 ">
  <!-- <div id="heroCarousel" class="carousel slide" data-bs-ride="carousel">

    <div class="carousel-inner">
      <div class="carousel-item active">
        <div class="carousel-container">
          <h2 class="animate__animated animate__fadeInDown">
            Welcome to <span>KesariMice</span>
          </h2>
          <p class="animate__animated animate__fadeInUp">
            Ut velit est quam dolor ad a aliquid qui aliquid. Sequi ea ut et est
            quaerat sequi nihil ut aliquam. Occaecati alias dolorem mollitia ut.
            Similique ea voluptatem. Esse doloremque accusamus repellendus
            deleniti vel. Minus et tempore modi architecto.
          </p>
          <a
            href="#about"
            class="btn-get-started animate__animated animate__fadeInUp scrollto"
          >
            Read More
          </a>
        </div>
      </div>
 
   
      <div class="carousel-item">
        <div class="carousel-container">
          <h2 class="animate__animated animate__fadeInDown">KesariMice</h2>
          <p class="animate__animated animate__fadeInUp">
            Ut velit est quam dolor ad a aliquid qui aliquid. Sequi ea ut et est
            quaerat sequi nihil ut aliquam. Occaecati alias dolorem mollitia ut.
            Similique ea voluptatem. Esse doloremque accusamus repellendus
            deleniti vel. Minus et tempore modi architecto.
          </p>
          <a
            href="#about"
            class="btn-get-started animate__animated animate__fadeInUp scrollto"
            >Read More</a
          >
        </div>
      </div>

      
      <div class="carousel-item">
        <div class="carousel-container">
          <h2 class="animate__animated animate__fadeInDown">
            Welcome to KesariMice
          </h2>
          <p class="animate__animated animate__fadeInUp">
            Ut velit est quam dolor ad a aliquid qui aliquid. Sequi ea ut et est
            quaerat sequi nihil ut aliquam. Occaecati alias dolorem mollitia ut.
            Similique ea voluptatem. Esse doloremque accusamus repellendus
            deleniti vel. Minus et tempore modi architecto.
          </p>
          <a
            href="#about"
            class="btn-get-started animate__animated animate__fadeInUp scrollto"
            >Read More</a
          >
        </div>
      </div>
    </div>
    <button
      class="carousel-control-prev"
      type="button"
      data-bs-target="#carouselExampleControls"
      data-bs-slide="prev"
    >
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Previous</span>
    </button>
    <button
      class="carousel-control-next"
      type="button"
      data-bs-target="#carouselExampleControls"
      data-bs-slide="next"
    >
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Next</span>
    </button>
  </div> -->

  
  <owl-carousel-o class="hm-slider" [options]="customOptions" *ngIf="!isMobile" >
    <ng-template carouselSlide *ngFor="let item of property" >
      <!-- <div class="slider" style="background-image: url(&quot;{{item.mobImage}}&quot;); " [@animate]="{value:'*',params:{delay:'5ms',x:'-25px'}}"> -->
        <div class="slider" style="background-image: url(&quot;{{item.mobImage}}&quot;); " [@animate]="{value:'*',params:{delay:'5ms',x:'-25px'}}">
        <div class="content-card">
          <h2 class="content-card__h1" >
            <!-- {{item.title}}  -->
            <!-- Discover The World<br> With Our Expert Tour Leader  -->
          </h2>
          <span class="content-card__span">
            <!-- {{item.description}}  -->
              <!-- Experience, accountability and innovation – all of which is reflected in the quality of our results.   -->
          </span><br>
          <!-- <button  type="button" class="btn btn-primary btn-sm fw-bold read-morebtn">Enquiry Now</button> -->
        </div>
        <div class="background-overlay"></div>


      </div>
      
    </ng-template>
  </owl-carousel-o>
  <owl-carousel-o class="hm-slider" [options]="customOptions" *ngIf="isMobile">
    <ng-template carouselSlide *ngFor="let item of property" >
      <div class="slider" style="background-image: url(&quot;{{item.image}}&quot;); " [@animate]="{value:'*',params:{delay:'5ms',x:'-25px'}}" >
        <div class="content-card">
          <h2 class="content-card__h1" >
            <!-- {{item.title}}  -->
            <!-- Discover The World<br> With Our Expert Tour Leader  -->
          </h2>
          <span class="content-card__span">
            <!-- {{item.description}}  -->
              <!-- Experience, accountability and innovation – all of which is reflected in the quality of our results.   -->
          </span><br>
          <!-- <button  type="button" class="btn btn-primary btn-sm fw-bold read-morebtn">Enquiry Now</button> -->
        </div>
        <div class="background-overlay"></div>
      </div>
      
    </ng-template>
  </owl-carousel-o>
</section>