<!-- ======= Hero Section ======= -->
<div [@animate]="{value:'*',params:{delay:'5ms',x:'-25px'}}">
  <!-- home carousel page -->
  <app-carousel></app-carousel>
  <!-- end home carousel page -->

  <main id="main">
    <!-- ======= Icon Boxes Section ======= -->
    <!-- home division -->
    <app-horizontal-view></app-horizontal-view>
    <!-- end horizontal-view -->

    <!--  about us  -->
    <app-listing-view></app-listing-view>
    <!-- about-us end -->

    <!-- about us slider -->
    <app-slider-view></app-slider-view>
    <!-- end about us slider -->

    <!--  about us accordion-->
    <app-accordion-view></app-accordion-view>
    <!--  end about us accordion -->

    <!-- service -->
    <app-vertical-view></app-vertical-view>
    <!-- end service -->

    <!--mice-toursim-->
    <app-dif-tepmt-card></app-dif-tepmt-card>
    <!-- end mice-tourism -->

    <!-- calltoaction -->
    <app-call-to-action></app-call-to-action>
    <!-- endcalltoaction -->

    <!--  PORTFOlio-->
    <app-tab-view></app-tab-view>
    <!-- end portforlio -->

    <!-- full accordion-view -->
    <app-full-accordion-view></app-full-accordion-view>
    <!-- end full accordion-view -->

    <!-- team -->
    <!-- <app-vertical-view-photos></app-vertical-view-photos> -->
    <!--  end team -->

    <!-- pricing-list -->
    <!-- <app-pricing-list></app-pricing-list> -->
    <!-- end pricing-list -->

    <!--  about us accordion-->
    <!-- <app-accordion-view></app-accordion-view> -->
    <!--  end about us accordion -->


    <app-career-section></app-career-section>
    <!-- contact-us -->
    <app-contact-us></app-contact-us>
    <!-- end contact-us -->
  </main>
</div>
<!-- End #main -->